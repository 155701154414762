import { graphql } from 'gatsby'
import React from 'react'
import { ImageSrc } from 'src/components/Image'
import Layout from 'src/components/Layout'
import { ReferenceQueryResult } from 'src/model/referenceQuery'
import { PageContext } from 'src/page/types'
import { css } from 'src/style/css'
import PageHeader from 'src/components/PageHeader'
import { t } from 'src/i18n/translation/t'
import Container from 'src/components/Container'
import { Theme } from 'src/style/Theme'
import Video from 'src/components/Video'
import { useTranslateData } from 'src/i18n/useTranslateData'
import { Msgs } from 'src/i18n/translation/types'
import { ImageGallery, Slider } from 'src/components/Slider'
import _ from 'lodash'
import CallToAction from 'src/components/CallToAction'

const getHostname = (url: string) => {
    return new URL(url).hostname
}

const ReferenceLink = ({
    link,
    children
}: {
    link: string
    children: string
}) => (
    <a
        href={link}
        target="_blank"
        aria-label={children}
        rel="noreferrer noopener"
        css={(th: Theme) => css`
            display: block;
            margin: 0 0 ${th.spacing.sm};
            font-size: ${th.fontSize.sm};
        `}
    >
        {children}
    </a>
)

const ReferenceDisplay = ({
    images,
    video,
    title
}: {
    images: ImageSrc[]
    video?: string
    title: string
}) => {
    const style = (th: Theme) => css`
        flex-basis: 65%;
        @media (max-width: ${th.mediaQuery.md}) {
            flex-basis: 100%;
        }
    `
    const displayedImages = images.map((gi) => ({
        src: gi,
        alt: title
    }))
    return video ? (
        <Video src={video} css={(th: Theme) => style(th)} />
    ) : (
        <div
            css={(th: Theme) => css`
                width: 65%;
                @media (max-width: ${th.mediaQuery.md}) {
                    width: 100%;
                }
            `}
        >
            <ImageGallery
                images={displayedImages}
                imageStyles={css`
                    height: 400px;
                `}
            />
        </div>
    )
}

const Content = ({
    reference,
    msgs
}: {
    reference: ReferenceQueryResult
    msgs: Msgs
}) => {
    const {
        title,
        descriptions,
        images,
        video,
        web,
        googlePlay,
        appStore,
        about
    } = reference

    const tData = useTranslateData()
    const slides =
        descriptions.length === images.length ? descriptions.length : 1
    return (
        <>
            <PageHeader>{tData(title)}</PageHeader>
            <Container>
                <Slider>
                    {_.times(slides).map((i) => {
                        const desc = descriptions[i]
                        const img = slides > 1 ? [images[i]] : images
                        const abt = about[i]
                            ? tData(about[i])
                            : t(msgs.references.about)
                        return (
                            <div key={i}>
                                <div
                                    css={(th: Theme) => css`
                                        display: flex;
                                        justify-content: space-between;
                                        @media (max-width: ${th.mediaQuery
                                                .md}) {
                                            flex-wrap: wrap;
                                        }
                                    `}
                                >
                                    <ReferenceDisplay
                                        images={img}
                                        video={video}
                                        title={tData(title)}
                                    />
                                    <div
                                        css={(th: Theme) => css`
                                            padding: 0 0 0 ${th.spacing.xl};
                                            flex-basis: 35%;
                                            @media (max-width: ${th.mediaQuery
                                                    .md}) {
                                                flex-basis: 100%;
                                                padding: ${th.spacing.xl} 0 0 0;
                                            }
                                            user-select: text;
                                        `}
                                    >
                                        <span
                                            css={(th: Theme) => css`
                                                font-size: ${th.fontSize.md};
                                                font-weight: ${th.fontWeight
                                                    .semiBold};
                                                display: block;
                                                border-bottom: 2px solid
                                                    ${th.colors.primary};
                                                padding: 0 0 ${th.spacing.md};
                                                margin: 0 0 ${th.spacing.lg};
                                            `}
                                        >
                                            {abt}
                                        </span>
                                        <p
                                            css={css`
                                                white-space: pre-line;
                                                text-align: justify;
                                            `}
                                        >
                                            {tData(desc)}
                                        </p>
                                        {googlePlay && (
                                            <ReferenceLink link={googlePlay}>
                                                Google Play
                                            </ReferenceLink>
                                        )}
                                        {appStore && (
                                            <ReferenceLink link={appStore}>
                                                App Store
                                            </ReferenceLink>
                                        )}
                                        {web && (
                                            <ReferenceLink link={web}>
                                                {getHostname(web)}
                                            </ReferenceLink>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </Container>
            <CallToAction linkText={t(msgs.references.callToAction.linkText)} />
        </>
    )
}

type ReferenceDetailProps = {
    data: {
        reference: ReferenceQueryResult
    }
    pageContext: PageContext
}

const ReferenceDetail = ({ data, pageContext }: ReferenceDetailProps) => {
    return (
        <Layout pageCtx={pageContext}>
            <Content reference={data.reference} msgs={pageContext.msgs} />
        </Layout>
    )
}

export default ReferenceDetail

export const pageQuery = graphql`
    query ReferenceDetailByID($id: String!) {
        reference(referenceId: { eq: $id }) {
            title {
                sk
                en
                de
            }
            about {
                sk
                en
                de
            }
            descriptions {
                sk
                en
                de
            }
            images {
                ...SrcImages
            }
            video
            web
            googlePlay
            appStore
        }
    }
`
